// IMPORTANT: THIS IS A GENERATED FILE AND SHOULD NOT BE EDITED OR CHECKED INTO VERSION CONTROL!
/* eslint-disable */
export const VERSION = {
    "dirty": true,
    "raw": "3bf40eddbf-dirty",
    "hash": "3bf40eddbf",
    "distance": null,
    "tag": null,
    "semver": null,
    "suffix": "3bf40eddbf-dirty",
    "semverString": null,
    "version": "5.31.3"
};
